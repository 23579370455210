import * as React from 'react';
import { Helmet } from 'react-helmet';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import MainContent from 'components/MainContent/MainContent';
import newsBg from 'images/news/news-background.png';

const Header = styled('div')`
  background: url(${newsBg});
  position: relative;
  left: 0;
  right: 0;
  height: 9.0625rem;
`;

const ListItem = styled('li')`
  font-family: 'Lato', sans-serif;
  font-size: 1.25rem;
  line-height: 1.5rem;
`;

const StyledLi = styled('li')`
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
`;

const HeaderTextContainer = styled('div')`
  display: flex;
  text-align: center;
  justify-content: center;
  color: white;
  height: 5.625rem;
`;

const StyledText = styled('p')`
  font-family: 'Lato', sans-serif;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: 3rem;
`;

const StyledLink = styled('a')`
  color: black; ;
`;

const HeaderText = styled('h2')`
  margin: 3.5rem;
`;

const TermsAndConditionsPage = () => {
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  return (
    <MainContent>
      <Helmet>
        <title>Terms and Conditions</title>
        <link rel='canonical' href={`${process.env.GATSBY_SITE_URL}/terms-and-conditions/`} />
      </Helmet>
      <Header>
        <HeaderTextContainer>
          <HeaderText>Terms & Conditions</HeaderText>
        </HeaderTextContainer>
      </Header>
      <Container>
        <ol>
          <StyledLi>Introduction</StyledLi>
          <StyledText>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Welcome! We're democratizing access to state-of-the-art venues by empowering performers to participate in
            the Web 3.0 Creator Economy, thereby fundamentally shifting the paradigm through reimagining fan engagement
            by building innovative proprietary technology that radically alters consumer content consumption habits and
            creates new opportunities. You are entering into an agreement with Live Bash, Inc. (“Live Bash”, “We”, “Our”
            or “Us”). This Agreement applies to anyone who visits Our Website, contacts Us and/or registers to use Our
            Services (“User,” “You,” “Your”, or “Yourself”). When You (individually or the entity that You represent)
            access and/or use Our Services, You are agreeing to be bound to the provisions set forth in these Terms of
            Service and any additional terms referenced herein, including Our <u>Privacy Notice</u> which sets out the
            terms in which We process any Personal Information collected from You or provided to Us (“Agreement”). If
            You do not agree with any terms of this Agreement, You must neither access nor use Our Services. In agreeing
            to this Agreement, You also represent that You are of the age of majority in Your jurisdiction and You have
            the authority to bind Yourself and/or the company You represent.
          </StyledText>
          <StyledLi>About Live Bash</StyledLi>
          <StyledText>
            <u>General</u>. This Agreement applies to all Our Services which include: (i) https://livebash.com
            (“Website”); (ii) the Live Bash Platform (“Platform”); the Live Bash Marketplace (“Marketplace”); and (iii)
            other solutions and products that We may make available to You (hereinafter, as applicable, referred to as
            “Services”). To access and use Services You must create an Account, adhere to the applicable terms of this
            Agreement and pay the applicable fees for Services.
          </StyledText>
          <StyledText>
            <u>Account</u>. In order to use Services, You may need to register and create an account as a Performer or
            Fan (“Account”). “Performers” are artists who stream their performances and/or make other content available
            through the Platform. “Fans” are individuals who are registered to view performances. Fans are also able to
            acquire tickets, merchandise and other material through the Marketplace. When creating an Account, You agree
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            to provide accurate, current, and complete information about Yourself ("Registration Data") and update it as
            necessary. If We have reason to believe that Your Registration Data is inaccurate, outdated, or incomplete,
            We may suspend or terminate Your Account. You agree You will not: (i) create an Account using a false
            identity or information that is not Your own; (ii) have more than one Account; or (iii) create an Account or
            use the Services if You have been previously removed from the Services. We reserve the right, in Our sole
            discretion, to accept or reject Your registration to use Our Services, as well as suspend or terminate Your
            Account for any reason. You are responsible for maintaining the confidentiality of Your password and Account
            and will be fully responsible for any and all activities that occur under Your Account. You agree to
            immediately notify Us of any unauthorized use of Your Account or any other breach of Account security.
          </StyledText>
          <StyledLi>The Platform</StyledLi>
          <StyledText>
            <u>Venues</u>. Performers who create an Account are eligible to reserve a Venue. “Venues” are Live Bash
            designated stage performance hall locations from which Performers produce content. Venues are available on a
            first-come, first-served basis and are not available in all jurisdictions. Lighting, sound, film and/or
            camera crews, mics, mixers, digital audio workstation, smoke machines, and other typical performance
            equipment are generally available at Venues. Please contact Us if You have questions about what is permitted
            on the premises of a Venue or if You have a need used in connection with Your performance. The following is
            prohibited at Venues and performances (as applicable):
          </StyledText>
          <ul style={{ listStyleType: 'space-counter' }}>
            <ListItem>The use of flames, fire, explosives, or fuel of any sort;</ListItem>
            <ListItem>Smoking, drinking, or the use of illegal drugs;</ListItem>
            <ListItem>Unauthorized use of Intellectual Property Rights of another;</ListItem>
            <ListItem>Nudity, excessive profanity, incitement of illegal activity, or riots;</ListItem>
            <ListItem>
              racist, sexist or lewd comments or gestures, comments and/or gestures directed at demeaning one’s race,
              gender identity, gender, military status, religion, economic status, nationality, disability, or other
              special characteristic associated with an individual or group of similar individuals;
            </ListItem>
            <ListItem>
              Defamation or other inflammatory or controversial content that reasonable individuals would find
              objectionable; and/or
            </ListItem>
            <ListItem>
              Destruction, damage, and/or the removal of fixtures, equipment and other materials located at the Venue.
            </ListItem>
          </ul>
          <StyledText>
            <u>Digital Collectibles</u>. All performances are recorded and/or livestreamed through the Platform.
            Performances, consequently, generate content that Fans may acquire as a Digital Collectible. Digital
            Collectibles are made available as “Moments” or “Splits”, which are particular segments of a performance,
            and “NFTs” which are the entire performance. Moments, Splits, and NFTs shall collectively hereinafter be
            referred to as “Digital Collectibles”. Digital Collectibles are created by Performers and are nonfungible
            tokens (“NFTs”) as further defined in Section 6. Digital Collectibles will be created and accessed on the
            Marketplace as further defined in Section 4. Live Bash may choose to offer additional services or cease to
            offer any Service currently provided, at any time, at Our sole discretion.
          </StyledText>
          <StyledLi>The Marketplace</StyledLi>
          <StyledText>
            The “Marketplace” is where: (a) Performers may make Digital Collectibles available for Fans to acquire, and
            (b) Fans can purchase Digital Collectibles as well as list Digital Collectibles for resale. Digital
            Collectibles may be listed for resale at a fixed fee or in an auction format which may be time limited
            and/or also require that a minimum reserve price be met to win the auction (“Listings”). You assume full
            responsibility for Listings offered for sale and/or acquired. All Listings must be accurate and up to date.
            Live Bash reserves the right to remove any Listing We determine in Our sole discretion to be inaccurate,
            misleading, and/or in violation of this Agreement. All Digital Collectibles sales are final, and the buyers
            will receive the acquired Digital Collectibles upon successful processing of the applicable payment. All
            payments are completed through Stripe and are subject to separate terms and conditions found at {''}
            <StyledLink href='https://stripe.com/legal/payment-terms'>
              https://stripe.com/legal/payment-terms
            </StyledLink>{' '}
            and at <StyledLink href='https://stripe.com/privacy'>https://stripe.com/privacy</StyledLink>. You must
            create and maintain an active Stripe account in order to buy or sell Digital Collectibles through the
            Marketplace. Live Bash reserves the right to modify the method of payment at any time.
          </StyledText>
          <StyledLi>Intellectual Property and Use Rights</StyledLi>
          <StyledText>
            <u>Intellectual Property Rights</u>. “Intellectual Property Rights” means any and all right, title, and
            interest, arising or existing at any time relevant hereto, anywhere in the world, including, but not limited
            to, all patent, patent registration, copyright, trademark, trade name, service mark, service name, trade
            secret, or other proprietary right arising or enforceable under any United States federal or state law,
            rule, or regulation, non-United States law, rule, or regulation, or international treaty in any technology,
            system, invention, discovery, know-how process, method, information, medium, or content, including, but not
            limited to, text, print, pictures, photographs, video, marks, logos, designs, drawings, artistic and
            graphical works, music, speech, computer software and documentation, any other works of authorship, and any
            form, method, or manner of expression or communication now known or hereinafter becoming known.
          </StyledText>
          <StyledText>
            <u>Proprietary Rights</u>. All Services, logos, trademarks, buttons icons, images, text, graphics,
            trainings, whitepapers, and other materials used in connection with Services, including any Intellectual
            Property Rights therein (collectively referred to as “Live Bash IP”), are owned and/or controlled by Live
            Bash and are accordingly protected under U.S. copyright laws and international treaties.{' '}
            <b>
              UNAUTHORIZED USE, COPYING, REPRODUCTION, MODIFICATION, REPUBLISHING, UPLOADING, DOWNLOADING, POSTING,
              TRANSMITTING, DISTRIBUTING OR DUPLICATING, OR ANY OTHER MISUSE OF LIVE BASH IP IS PROHIBITED.
            </b>
          </StyledText>
          <StyledText>
            <u>Live Bash</u>. Live Bash hereby grants to You a limited, non-exclusive, revocable, non-commercial,
            non-sublicensable, non-assignable, personal, and non-transferable license to download, display, view, or use
            Services as set forth herein. We expressly reserve all rights not expressly set forth herein. The design of
            Services including the text, scripts, graphics, interactive features, and the trademarks, service marks, and
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            logos contained therein ("Our Content"), are owned by or licensed to Us and are subject to copyright and
            other Intellectual Property Rights under United States and foreign laws and international conventions. You
            agree not to engage in the misuse, copying and/or redistribution of Our Content contained within Services.
          </StyledText>
          <StyledText>
            <u>Your Content</u>. You hereby grant Live Bash a non-exclusive, fully-paid, royalty-free, world-wide,
            universal, and transferable license to: display, publicly perform, distribute, store, broadcast, transmit,
            and reproduce Your logo(s), service marks, trademarks and trade names, and perform, distribute, store,
            broadcast, transmit, reproduce, modify, prepare derivative works of, and otherwise use and reuse all or any
            part of any information that You submit in connection with Your use of Services (“Your Content”). You hereby
            represent and warrant that You own all rights to Your Content or, alternatively, that You have the right to
            give Us the license described above. You represent and warrant that Your Content does not infringe on the
            Intellectual Property Rights, privacy rights, publicity rights, or other legal rights of any third party.
            You waive and agree never to assert any and all moral rights in and to all of the materials licensed in this
            Section. We reserve the right to display advertisements in connection with Your Content. We are not required
            to host, display, or distribute any of Your Content and We may refuse to accept or transmit Your Content,
            and may remove or delete all or any portion of Your Content from Our Services at any time.
          </StyledText>
          <StyledText>
            <u>Offensive Content</u>. If You think Your Content might offend someone or be embarrassing to someone, then
            chances are it probably will, and it doesn’t belong on the Service. Cursing, harassing, stalking, insulting
            comments, personal attacks, gossip, and similar actions are prohibited. Your Content must not threaten,
            abuse, or harm others, and it must not include any negative comments that are connected to race, national
            origin, gender, sexual orientation, religion, or handicap. Your Content must not be defamatory, slanderous,
            indecent, obscene, pornographic, or sexually explicit. Your Content must not exploit children under the age
            of 18. Without limitation, Live Bash may, but does not commit to, addressing issues that come to Our
            attention that We believe are offensive, obscene, lewd, lascivious, filthy, violent, harassing, threatening,
            abusive, illegal, or otherwise objectionable or inappropriate, and/or to enforce the rights of third parties
            or these Terms of Service.
          </StyledText>
          <StyledText>
            <u>Do Not Use for Inappropriate Purposes</u>. Your Content must not promote any infringing, illegal, or
            other similarly inappropriate activity.
          </StyledText>
          <StyledText>
            <u>Be Honest and Do Not Misrepresent Yourself or Your Content</u>. Do not impersonate any other individual,
            User, or company, and do not submit Your Content that You believe may be false, fraudulent, deceptive,
            inaccurate, or misleading, or that misrepresents Your identity or affiliation with a person or company. In
            the event You receive anything in consideration from Live Bash with respect to Your Content (e.g., coupons,
            sweepstakes entries, etc.), You represent You will include disclosure of the receipt of this consideration
            clearly and conspicuously as part of Your Content and include any other disclosures We may require.
          </StyledText>
          <StyledText>
            <u>Violations</u>. If Live Bash reasonably believes that Your Content violates these Terms of Service, Live
            Bash may take any legally available action that We deem appropriate, in Our sole discretion. We may require,
            at any time, proof of the permissions referred to above in a form acceptable to Us. Failure to provide such
            proof may lead to, among other things, Your removal from use of the Services.
          </StyledText>
          <StyledLi>Non-fungible Tokens (“NFTs”).</StyledLi>
          <StyledText>
            (a) <u>Non-fungible tokens (“NFTs”)</u>. A non-fungible token (“NFT”) means a one-of-a kind digital asset
            stored, and transferred on a blockchain network, with ownership and transaction history recorded and
            verified on that network’s blockchain (i.e., digital ledger) and that provides the right to view and display
            a specific online digital media file (“NFT Media”). Digital Collectibles on the Live Bash Platform will
            exist as NFTs on a private, permissioned, EVM compatible blockchain supported by a single node that is
            operated by Live Bash, with NFT Media originally authored or created by Performers.
          </StyledText>
          <StyledText>
            <u>Custody and Transfer</u>. Performers create and mint Digital Collectibles as NFTs through Services. All
            NFTs are initially owned and custodied by Performers. Thereafter, such NFTs shall be freely transferable on
            the Marketplace to any third party at Your sole discretion, and You shall have all rights necessary to
            effectuate such transfers. However, any such transfer shall be at Your own risk and expense, and Live Bash
            shall have no liability to You as more fully outlined in Section 6(j) below.
          </StyledText>
          <StyledText>
            <u>Limited License</u>. You or any third-party transferee of the NFTs (“NFT Holders”) shall have the right
            to custody the NFT on the Live Bash private, permissioned, EVM compatible blockchain and shall have the
            right to view and display the NFT Media – for non-commercial and personal purposes only, and consistent with
            these Terms of Service and applicable law. You or any NFT Holders do not have the right to (i) modify the
            NFT Media in any way, including, without limitation, the shapes, designs, drawings, attributes, or color
            schemes; (ii) use the NFT Media to advertise, market, or sell any third-party product or service; (iii) use
            the NFT Media in connection with images, videos, or other forms of media that depict hatred, intolerance,
            violence, cruelty, or anything else that could reasonably be found to constitute hate speech or otherwise
            infringe upon the rights of others; (iv) use the NFT Media in movies, videos, or any other forms of media,
            except to the limited extent that such use is expressly permitted in these Terms of Service or solely for
            Your own personal, non-commercial use; (v) sell, distribute for commercial gain (including, without
            limitation, giving away in the hopes of eventual commercial gain), or otherwise commercialize merchandise
            that includes, contains, or consists of the NFT Media, except as expressly permitted in these Terms; (vi)
            attempt to trademark, copyright, or otherwise acquire additional intellectual property rights in or to the
            NFT Media; (vii) create derivative works based on the NFT Media, or (viii) otherwise utilize the NFT Media
            for Your or any third party’s commercial benefit.
          </StyledText>
          <StyledText>
            <u>Other General Rights</u>. At the sole discretion of Live Bash, and subject to any additional terms and
            conditions as may be imposed by Live Bash in Our sole discretion, NFT Holders may, in some instances, be
            granted the right to receive physical/consumptive items of nominal value and may be granted certain access
            rights.
          </StyledText>
          <StyledText>
            <u>Rights Specific to Certain NFTs</u>. Live Bash, in Our sole discretion, reserves the right to impose
            restrictions and/or grant NFT Holders with additional rights as may be applicable through a separate
            agreement with NFT Holders.
          </StyledText>
          <StyledText>
            <u>No Other Rights Granted</u>. Except for the rights specifically set forth in this Section 6 of these
            Service, You shall be entitled to no other rights or benefits with respect to any NFTs or NFT Media.
          </StyledText>
          <StyledText>
            <u>Other Intellectual Property</u>. Live Bash shall be the sole owner of any derivative content (“Derivative
            Content”) generated during Your creation or minting of NFTs using the Services. Such Derivative Content
            includes, but is not limited to, all text, software, scripts, code, designs, graphics, photos, sounds,
            music, videos, applications, interactive features, articles, news stories, sketches, animations, textures,
            lighting, camera work, background, color schemes, algorithms, materials, patterns, animations, physical
            items, general artwork, and/or other related content.
          </StyledText>
          <StyledText>
            <u>Royalties</u>. Any NFT You create using Services may now or in the future be designed with a “perpetual
            feature, whereby any time the NFT is transferred by You to a third party, or from a third party to another
            another third party, a defined percentage of the purchase price may be automatically sent to Live Bash
            Payment”). Any such Royalty Payment made is based on Live Bash’s ownership interest in and to the copyright
            in the NFT Media.
          </StyledText>
          <StyledText>
            <u>NFT Burning or Disassociation</u>. Live Bash reserves the right to (i) “burn” (i.e., to obtain and
            otherwise neutralize) any NFT, or (ii) disassociate the NFT Media from any NFT created or minted using the
            Services that violates these Terms of Service, any applicable law or regulation, or that Live Bash, in Our
            sole discretion, determines to be harmful in any way to Live Bash, You, or any third party.
          </StyledText>
          <StyledText>
            <u>Reservation of Rights</u>. Live Bash hereby reserves all rights not expressly set forth in these Terms
            respect to any NFT Media or NFTs created or minted through Your use of the Services.
          </StyledText>
          <StyledText>
            <u>NFT Transfers</u>. Live Bash has no control over the interaction of Users of Our Services, NFT Media, or
            financial transactions related to the same (“NFT Transfer”). Live Bash has no liability to You or any third
            party for any claims or damages that may arise as a result of any NFT Transfer in which You engage.
          </StyledText>
          <StyledText>
            <u>Taxes</u>. You will be solely responsible for paying any and all sales, use, value-added, and other
            duties, and assessments (except taxes on Live Bash’s net income) now or hereafter claimed or imposed by any
            governmental authority associated with Your use of the Services.
          </StyledText>
          <StyledLi>Warranty Disclaimer, Limitation of Liability and Indemnification</StyledLi>
          <StyledText>
            <u>DISCLAIMER</u>. SERVICES ARE PROVIDED TO YOU “AS IS” AND WITH ALL FAULTS AND DEFECTS AND WITHOUT WARRANTY
            KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, LIVE BASH, ON OUR OWN BEHALF AND ON BEHALF OF
            OUR AFFILIATES AND OUR RESPECTIVE LICENSORS, RESELLERS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL
            WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO SERVICES AND NFT MEDIA,
            INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
            NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR
            TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, LIVE BASH PROVIDES NO WARRANTY OR UNDERTAKING, AND
            MAKES NO REPRESENTATION OF ANY KIND, THAT THE SERVICES WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED
            RESULTS, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, BE ERROR FREE, OR THAT
            ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.
          </StyledText>
          <StyledText>
            IF YOU ARE A RESIDENT OF A JURISDICTION THAT REQUIRES A SPECIFIC STATEMENT REGARDING RELEASE, THEN THE
            FOLLOWING APPLIES. FOR EXAMPLE, CALIFORNIA RESIDENTS MUST, AS A CONDITION OF THIS AGREEMENT, WAIVE THE
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            APPLICABILITY OF CALIFORNIA CIVIL CODE SECTION 1542, WHICH STATES, "A GENERAL RELEASE DOES NOT EXTEND TO
            CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE
            TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY." YOU HEREBY WAIVE THIS SECTION OF THE CALIFORNIA CIVIL CODE.
            YOU HEREBY WAIVE ANY SIMILAR PROVISION IN LAW, REGULATION, OR CODE THAT HAS THE SAME INTENT OR EFFECT AS THE
            AFOREMENTIONED PROVISION.
          </StyledText>
          <StyledText>
            <u>LIMITATION OF LIABILITY</u>. TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU UNDERSTAND AND AGREE THAT IN NO
            SHALL LIVE BASH AND OUR AFFILIATES, RESELLERS, LICENSORS, DIRECTORS, OFFICERS, AND/OR EMPLOYEES (“LIVE BASH
            PARTIES”) BE LIABLE FOR (i) ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES ARISING
            OUT OF OR IN CONNECTION WITH SERVICES, WHETHER OR NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS ARISING OUT OF OR IN
            CONNECTION WITH SERVICES, INCLUDING DAW COMPATIBILITY AND/OR THIS AGREEMENT, ON ANY THEORY OF LIABILITY
            (INCLUDING CONTRACT OR TORT INCLUDING NEGLIGENCE, OR HOWSOEVER OTHERWISE) ARISING OUT OF, IN CONNECTION
            WITH, OR RESULTING FROM THE USE OR INABILITY TO USE SERVICES; OR (ii) ANY OTHER MATTER RELATED TO SERVICES.
          </StyledText>
          <StyledText>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, UNDER NO CIRCUMSTANCES WILL WE BE LIABLE TO YOU, REGARDLESS OF
            WHETHER THE CLAIM IS BASED IN CONTRACT OR TORT INCLUDING NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, FOR
            MORE THAN THE GREATER OF THE TOTAL AMOUNT YOU PAID LIVE BASH FOR USE OF SERVICES PERIOD PRECEDING THE DATE
            THE CAUSE OF ACTION FIRST AROSE.
          </StyledText>
          <StyledLi>Indemnification</StyledLi>
          <StyledText>
            <u>Your indemnity</u>. You agree to defend and indemnify Live Bash Parties from and against any third-party
            including reasonable attorney’s fees, court costs, settlements, and disbursements, from or relating to: (i)
            Your Account; (ii) Your use of and access to the Services in violation of this Agreement; (iii) Your
            Content; (iv) NFT Media/Digital Collectibles; (v) Your violation of this Agreement; (vi) Your violation of
            any law, rule, or regulation, or the rights of any third-party, including Your upload or provision of
            personally identifiable information through Services; (v) Your interactions with other Users of Services;
            and (vi) dispute or issue related to any NFT Transfer.
          </StyledText>
          <StyledText>
            9. <u>Defense</u>. We shall indemnify, defend and hold You harmless from any and all liabilities, losses,
            suits, penalties, fines, costs, or expenses (including, reasonable attorneys’ fees and other applicable
            expenses) relating to third-party claims that Services infringe upon that party’s Intellectual Property
            Rights (“Infringement Claim”). Our obligation under this Section 8(b) is contingent upon Your prompt written
            notice of any such claim and reasonable assistance in defending any such Infringement Claim.
          </StyledText>
          <StyledLi>Choice of Law, Dispute Resolution, Class Action Waiver</StyledLi>
          <StyledText>
            <u>Choice of Law</u>. The Agreement shall be governed by and construed in accordance with the laws of the
            Illinois and You agree to the exclusive jurisdictions of the State of Illinois. To the extent applicable law
            permits the provisions set forth in this Agreement shall prevail in the event of conflict with any other
            law.
          </StyledText>
          <StyledText>
            <u>Dispute Resolution</u>. Any dispute or controversy arising out of, relating to, or concerning any
            interpretation, construction, performance, or breach of this Agreement, will be settled by arbitration to be
            held in Chicago, Illinois in accordance with the rules then in effect of the American Arbitration
            Association. The arbitrator may grant injunctions or other relief in the dispute or controversy. The
            decision of the arbitrator will be final, conclusive, and binding on the parties to the arbitration.
            Judgment may be entered on the arbitrator’s decision in any court having jurisdiction. The prevailing party
            shall be entitled to recover from the non-prevailing party the prevailing party’s actual attorneys’ fees and
            costs (including, without limitation, all taxable and non-taxable costs, and all fees and costs to determine
            the amount of fees and costs to be awarded) incurred in connection with arbitration and enforcement of the
            judgment. No dispute may be brought by either party eighteen (18) months after the occurrence of the event
            giving rise to any such claims. Notwithstanding the foregoing, Live Bash may seek injunctive or other
            equitable relief to protect Our Intellectual Property Rights in any court of competent jurisdiction.
          </StyledText>
          <StyledText>
            <u>Judicial Forum for Legal Disputes</u>. In the event that the agreement to arbitrate above is found not to
            to You or to a particular claim or dispute, because You are an international User to which arbitration does
            not apply, You agree (except as otherwise provided by law) that any claim or dispute that has arisen or may
            arise between You and Live Bash must be resolved exclusively by applicable court located in Chicago,
            Illinois. You and Live Bash agree to submit to the exclusive personal jurisdiction of the courts located
            within Chicago, Illinois for the purpose of litigating all such claims or disputes.
          </StyledText>
          <StyledText>
            <u>CLASS ACTION WAIVER</u>. YOU AND LIVE BASH AGREE THAT ANY PROCEEDING TO RESOLVE ANY DISPUTE, WHETHER IN
            ARBITRATION, OR OTHERWISE WILL BE CONDUCTED SOLELY ON AN INDIVIDUAL BASIS, AND THAT NEITHER YOU NOR LIVE
            BASH WILL SEEK TO HAVE ANY DISPUTE HEARD AS A CLASS ACTION, A REPRESENTATIVE ACTION, A COLLECTIVE ACTION, A
            PRIVATE ATTORNEY – GENERAL ACTION, OR IN ANY PROCEEDING IN WHICH YOU OR LIVE BASH ACTS OR PROPOSES TO ACT IN
            A REPRESENTATIVE CAPACITY. YOU AND LIVE BASH FURTHER AGREE THAT NO ARBITRATION OR OTHER PROCEEDING WILL BE
            JOINED, CONSOLIDATED, OR COMBINED WITH ANOTHER ARBITRATION OR OTHER PROCEEDING WITHOUT THE PRIOR WRITTEN
            CONSENT OF LIVE BASH AND ALL PARTIES TO THE APPLICABLE PROCEEDINGS.
          </StyledText>
          <StyledText>
            <u>JURY WAIVER</u>. AS PERMITTED BY APPLICABLE LAW, THE PARTIES HEREBY WAIVE THEIR RIGHT TO JURY TRIAL WITH
            TO ALL CLAIMS AND ISSUES ARISING UNDER, IN CONNECTION WITH, TOUCHING UPON, OR RELATING TO THIS AGREEMENT
            AND/OR THE SCOPE OF THE PROVISIONS OF THIS SECTION 9, WHETHER SOUNDING IN CONTRACT OR TORT, AND INCLUDING
            ANY CLAIM FOR FRAUDULENT INDUCEMENT THEREOF.
          </StyledText>
          <StyledLi>Miscellaneous</StyledLi>
          <StyledText>
            <u>Force Majeure</u>. Live Bash will not be liable for any failure in performance due to causes beyond Our
            reasonable control or actions or omissions of regulatory or governmental bodies (including the passage of
            laws or regulations or other acts of government that impact the delivery of Services).
          </StyledText>
          <StyledText>
            <u>Severability</u>. If any provision of this Agreement is declared or found to be illegal, unenforceable,
            invalid, then, to the full extent permitted by law, (i) the provision found to be illegal, unenforceable, or
            or void shall be deemed amended and the court having jurisdiction shall be requested to reform such
            provision to the extent necessary to make it legal and enforceable while preserving the intents of the
            reflected therein; and (ii) such illegality, unenforceability, or invalidity will not affect or impair the
            remaining provisions, which shall continue in full force and effect.
          </StyledText>
          <StyledText>
            <u>Amendments; No Waiver</u>. Any term of the Agreement may be amended, and the observance of any term may
            waived, but only by the written consent of the parties. The failure of a party at any time or times to
            require performance of any provision hereof shall in no manner affect its right at a later time to enforce
            the same, unless the same is waived in writing. The rights and remedies of the parties hereunder shall not
            be mutually exclusive, and the exercise of one or more of the provisions hereof shall not preclude the
            exercise of any other provisions hereof.
          </StyledText>
          <StyledText>
            <u>No Assignment</u>. You may neither assign this Agreement nor any rights or obligations of hereinunder, in
            or in part, whether voluntary, by operation of contract, law or otherwise, without Our prior written
            consent. Any attempted assignment or transfer in violation of the foregoing will be null and void. Live Bash
            may freely assign or otherwise transfer all or any of Our rights, or delegate or otherwise transfer all or
            any of Our obligations or performance, under this Agreement without Your consent. This Agreement is binding
            upon and inures to the benefit of the parties hereto and their respective permitted successors and assigns.
          </StyledText>
          <StyledText>
            <u>Customer Service</u>. While most customer support inquiries are answered at Our Website You can also via
            via email at <a href='mailto:hey@livebash.com'>hey@livebash.com</a> with any questions You may have about
            this Agreement or Services.
          </StyledText>
          <StyledText>
            <u>Term and Termination</u>. This Agreement and the license granted hereunder shall remain in effect unless
            until terminated as set forth herein (“Term“). You may terminate this Agreement by cancelling Your Account
            or by ceasing to use of Services. Live Bash may immediately terminate this Agreement and disable or delete
            Your Account if You materially breach this Agreement. Upon termination of this Agreement, the license
            granted hereunder shall also terminate. No termination shall affect Your obligation to pay any and all fees
            that may have become due before such expiration or termination, or entitle You to any refund, unless
            otherwise provided herein.
          </StyledText>
          <StyledText>
            <u>Communication</u>. You agree that We will provide notices and messages to You via email and/or other
            information that You provided Us. You are responsible for providing Us with Your most current e-mail
            address. If You have provided an invalid email, or such address is not capable of receiving notices, such
            email notification will nonetheless constitute effective notice. Further, You consent to receive
            communications from Us electronically, and agree that all terms, conditions, or otherwise, provided to Your
            electronically satisfy any legal requirement that would be satisfied if they were in writing. You may give
            notice to Live Bash at the following address: [ADDRESS PLACE HOLDER]. Notices shall be deemed given when
            received by Live Bash delivered by overnight delivery service or first-class postage prepaid mail.
          </StyledText>
          <StyledText>
            <u>California Users and Residents</u>. Pursuant to California Civil Code Section 1789.3, questions about
            complaints, or inquiries must be addressed to Our agent for notice and sent via certified mail to: Live Bash
            1801 W Berteau Ave Chicago IL 60613. California Users are also entitled to the following specific consumer
            rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California
            Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Sacramento, CA 95834,
            or by telephone at (916) 445-1254 or (800) 952-5210.
          </StyledText>
          <StyledText>
            <u>Modifications</u>.{' '}
            <b>THIS AGREEMENT AND SERVICES ARE SUBJECT TO CHANGE BY LIVE BASH IN OUR SOLE DISCRETION AT ANY TIME.</b>
            When changes are made, We will make a new copy of the Agreement available by posting on the Website.
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            We will also update the "Last Revised" date at the top of the Agreement. If We make any material changes,
            and You have an Account to use the Services, We may also notify You via the email associated with Your
            Account. Changes will be effective immediately. If You do not agree to any change(s), You agree to stop
            using Services. Your continued use of the Services constitutes Your acceptance of such change(s).{' '}
            <b>YOU AGREE TO REGULARLY CHECK THE SERVICES TO VIEW THE THEN-CURRENT TERMS OF YOUR AGREEMENT WITH US.</b>
          </StyledText>
          <StyledText>
            <u>Digital Millennium Copyright Act Take-Down Notices</u>. If You are a copyright owner or an agent thereof
            believe, in good faith, that any materials provided on Services (“Content”) infringe upon Your copyrights,
            copyrights, You may submit a notification pursuant to the Digital Millennium Copyright Act (“DMCA”) by
            sending the following information in writing to Our designated copyright agent at [DMCA agent].
          </StyledText>
          <ul style={{ listStyleType: 'upper-roman' }}>
            <ListItem>The date of Your notification;</ListItem>
            <ListItem>
              A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive
              right that is allegedly infringed;
            </ListItem>
            <ListItem>A description of the copyrighted work claimed to have been infringed;</ListItem>
            <ListItem>
              A description of the material that is claimed to be infringing or to be the subject of infringing activity
              and information sufficient to enable Us to locate such work;
            </ListItem>
            <ListItem>
              Information reasonably sufficient to permit Us to contact You, such as an address, telephone number,
              and/or email address;
            </ListItem>
            <ListItem>
              A statement that You have a good faith belief that use of the material in the manner complained of is not
              authorized by the copyright owner, its agent, or the law; and
            </ListItem>
            <ListItem>
              A statement that the information in the notification is accurate, and under penalty of perjury, that You
              are the owner, or are authorized to act on behalf of the owner, of an exclusive right that is allegedly
              infringed.
            </ListItem>
          </ul>
          <StyledText>
            <u>Counter-Notices</u>. If You believe that the Content that has been removed is not infringing, or that You
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            have the authorization from the copyright owner, the copyright owner's agent, or pursuant to the law, to
            post and use Content, You may send a counter-notice containing the following information to Our copyright
            agent using the contact information set forth above:
          </StyledText>
          <ul style={{ listStyleType: 'upper-roman' }}>
            <ListItem>Your physical or electronic signature;</ListItem>
            <ListItem>
              A description of the content that has been removed and the location at which the content appeared before
              it was removed;
            </ListItem>
            <ListItem>
              A statement that You have a good faith belief that the content was removed as a result of mistake or
              misidentification of the content; and
            </ListItem>
            <ListItem>
              Your name, address, telephone number, and email address, a statement that You consent to the jurisdiction
              of the federal courts in Chicago, Illinois, and a statement that You will accept service of process from
              the person who provided notification of the alleged infringement.
            </ListItem>
          </ul>
          <StyledText>
            If a counter-notice is received by Our copyright agent, We may send a copy of the counter-notice to the
            original complaining party informing such person that We may reinstate the removed Content within ten (10)
            business days. Unless the copyright owner files an action seeking a court order against the User who posted
            the allegedly infringing content, the Content may (in Our sole discretion) be reinstated in Services within
            ten (10) business days after receipt of the counter-notice.
          </StyledText>
          <StyledText>
            <u>Contact Live Bash</u>. For questions or comments concerning this Agreement, please refer contact Us
            directly via email at{' '}
            <u>
              <a href='mailto:hey@livebash.com'>hey@livebash.com</a>
            </u>
            .
          </StyledText>
        </ol>
      </Container>
    </MainContent>
  );
};

export default TermsAndConditionsPage;
